import React, { Component } from 'react';
import logo from './fotoLN.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            My name is <code>Ivan Zerlotti</code> - software developer and analyst
          </p>
          <p>
            This site is under construction. Please come back to see more in the next future
          </p>        
        </header>
      </div>
    );
  }
}

export default App;
